import useGlobalSettings from '@components/_hooks/useGlobalSettings';
import Image from 'next/image'
import Link from 'next/link';

const HeaderLogo = () => {
    const { getSetting } = useGlobalSettings();
    const width = getSetting('header_logo_width', 150);
    const height = getSetting('header_logo_height', 50);

    return (
        <Link href='/'>
            <Image style={{objectFit: 'contain'}} priority={true} src={ `/images/${process.env.brand.acronym}/logo-main.png` } alt={'logo-main'} width={width}  height={height} quality={75}/>
        </Link>
    )
}

export default HeaderLogo